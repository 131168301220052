export const studies = [
  {
    date: "2017 - 2021",
    degree: "Ingeniería en Sistemas de Información",
    institution: "Universidad Tecnológica Nacional F.R.Ro.",
    url: "https://www.frro.utn.edu.ar/",
  },
  {
    date: "2016",
    degree: "Bachiller en Comunicación",
    institution: "Colegio ICADE Pergamino",
  },
];
