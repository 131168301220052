export const languages = [
  {
    name: "Español",
    level: "Nativo",
  },
  {
    name: "Inglés",
    level: "Intermedio Avanzado",
  },
];
