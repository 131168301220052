export const courses = [
  {
    date: "2021 - 2022",
    degree: "Curso de Three.js",
    institution: "Three.js Journey - Bruno Simon",
    url: "https://threejs-journey.com/",
  },
  {
    date: "2018",
    degree: "Desarrollo de Apps Móviles",
    institution: "Comunidad IT",
    url: "https://www.comunidadit.org/",
  },
];
