export const links = [
  {
    url: "https://github.com/motiontx",
    display: "Mi GitHub",
  },
  {
    url: "https://www.linkedin.com/in/vittorio-retrivi/",
    display: "Mi LinkedIn",
  },
];
