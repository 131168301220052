export const languages = [
  {
    name: "Spanish",
    level: "Native",
  },
  {
    name: "English",
    level: "Upper Intermediate",
  },
];
