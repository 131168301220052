export const courses = [
  {
    date: "2021 - 2022",
    degree: "Three.js Course",
    institution: "Three.js Journey - Bruno Simon",
    url: "https://threejs-journey.com/",
  },
  {
    date: "2018",
    degree: "Mobile App Development",
    institution: "Comunidad IT",
    url: "https://www.comunidadit.org/",
  },
];
